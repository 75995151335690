import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { openSaveChanges } from '../../../store/ui';
import { updateSelf, uploadUserAvatar } from '../../../store/users';
import Input from '../../inputs/input';
import Image from '../../utils/image';
import SaveChanges from '../../utils/save-changes';
import FileInput from '../../inputs/file-input';
import { size } from 'cypress/types/lodash';

const UserSettingsProfile: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const user = useSelector((s: Store.AppState) => s.auth.user)!;
  const { register, handleSubmit, setValue } = useForm();

  const onSave = (e) => {
    const onUpdate = (payload) => dispatch(updateSelf(payload));
    handleSubmit(onUpdate)(e);
  };

  return (
    <div className="flex flex-col pt-14 px-10 pb-20 h-full mt-1">
      <form onChange={() => dispatch(openSaveChanges(true))}>
        <header>
          <h1 className="text-xl font-bold inline">My Profile</h1>
        </header>

        {/* <section className="w-1/2"> */}

          <div className="grid grid-cols-2 grid-rows-1 gap-4">
            <div >
              <Input
            label="Username"
            name="username"
            register={register}
            options={{ value: user.username }}
            className="pt-5" />

          <FileInput
            name="avatarURL"
            options={{ value: user.avatarURL }}
            onChange={(e) => {
              const file = e.currentTarget?.files?.[0];
              if (file) dispatch(uploadUserAvatar(file));
            }} />
            
          <Input
            label="About"
            name="about"
            register={register}
            options={{ value: user.about }}
            className="pt-5" />
            </div>
            <div >2</div>
          </div>

          
          <div className="relative avatar mr-2">

            <Image
              className="object-scale-down h-12 w-12"
              src={`${process.env.REACT_APP_CDN_URL}${user.avatarURL}`} />
          </div>

        {/* </section> */}

        <SaveChanges
          setValue={setValue}
          onSave={onSave}
          obj={user} />
      </form>
    </div>
  );
}

export default UserSettingsProfile;