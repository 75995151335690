import { Entity } from '@hamcord/types';
import { FunctionComponent } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import { useSelector } from 'react-redux';
import { getMember } from '../../../store/members';
import { getMemberHighestRole } from '../../../store/roles';
import MessageTimestamp from './message-timestamp';

interface MessageHeaderProps {
  message: Entity.Message;
  author?: Entity.User;
  isExtra?: boolean;
}

const MessageHeader: FunctionComponent<MessageHeaderProps> = ({ author, message, isExtra = false }) => {
  const guild = useSelector((s: Store.AppState) => s.ui.activeGuild)!;
  
  if (isExtra) return null;

  if (!guild){
    return (!message.system && author) ? (
      <div>
        <ContextMenuTrigger id={author.id}>
          <span
            // style={'var(--font)' }
            className="context-menu text-base heading hover:underline cursor-pointer mr-2">{author.username}</span>
        </ContextMenuTrigger>
        <span className="text-xs muted">
          <MessageTimestamp message={message} />
        </span>
      </div>
    ) : (
      <span className="text-xs muted">
        <MessageTimestamp message={message} />
      </span>
    );

  } else {
    const member = useSelector(getMember(guild.id, message.authorId));
    const highestRole = useSelector(getMemberHighestRole(guild.id, member?.userId ?? ''));

    return (!message.system && author) ? (
      <div>
        <ContextMenuTrigger id={author.id}>
          <span
            style={{ color: highestRole?.color ?? 'var(--font)' }}
            className="context-menu text-base heading hover:underline cursor-pointer mr-2">{author.username}</span>
        </ContextMenuTrigger>
        <span className="text-xs muted">
          <MessageTimestamp message={message} />
        </span>
      </div>
    ) : (
      <span className="text-xs muted">
        <MessageTimestamp message={message} />
      </span>
    );
  }
}

export default MessageHeader;