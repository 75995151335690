import { Entity } from '@hamcord/types';
import { faClipboard, faCog, faDoorOpen, faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { useDispatch, useSelector } from 'react-redux';
import { leaveGuild } from '../../store/members';
import DevModeMenuSection from './dev-mode-menu-section';
import usePerms from '../../hooks/use-perms';
import { actions as ui } from '../../store/ui';

export interface GuildMenuProps {
  guild: Entity.Guild;
}

const GuildMenu: React.FunctionComponent<GuildMenuProps> = ({ guild }) => {
  const dispatch = useDispatch();
  const devMode = useSelector((s: Store.AppState) => s.config.devMode);
  const perms = usePerms();

  return (
    <ContextMenu
      key={guild.id}
      id={guild.id}
      className="bg-bg-tertiary rounded shadow w-56 p-2">
      {perms.can('CREATE_INVITE', guild.id) && (
        <MenuItem
          className="flex items-center justify-between cursor-pointer"
          onClick={() => dispatch(ui.openedModal('GuildSettings'))}>
          <span className="primary">Invite people</span>
          <FontAwesomeIcon
            className="float-right w-1"
            icon={faUserPlus} />
        </MenuItem>
      )}

      <hr className="my-2 border-bg-primary" />
      {(perms.can('MANAGE_GUILD', guild.id)
        || perms.can('MANAGE_ROLES', guild.id)
        || perms.can('MANAGE_INVITES', guild.id)) && (
          <MenuItem
            className="flex items-center justify-between cursor-pointer"
            onClick={() => dispatch(ui.openedModal('GuildSettings'))}>
            <span>Guild Settings</span>
            <FontAwesomeIcon icon={faCog} />
          </MenuItem>
        )}
        {(perms.can('MANAGE_ROLES', guild.id)) && (
          <MenuItem
            className="flex items-center justify-between cursor-pointer"
            onClick={() => dispatch(ui.openedModal('GuildSettingsRoles'))}>
            <span>Guild Roles</span>
            <FontAwesomeIcon icon={faCog} />
          </MenuItem>
        )}
        {(perms.can('MANAGE_INVITES', guild.id)) && (
          <MenuItem
            className="flex items-center justify-between cursor-pointer"
            onClick={() => dispatch(ui.openedModal('GuildInvites'))}>
            <span>Guild Invites</span>
            <FontAwesomeIcon icon={faCog} />
          </MenuItem>
        )}
      <hr className="my-2 border-bg-primary" />
      {(perms.can('MANAGE_CHANNELS', guild.id)) && (
          <MenuItem
            className="flex items-center justify-between cursor-pointer"
            onClick={() => dispatch(ui.openedModal('CreateChannel'))}>
            <span>Create Channel</span>
            <FontAwesomeIcon icon={faPlus} />
          </MenuItem>
        )} 
      {/* <hr className="my-2 border-bg-primary" />
      {(perms.can('MANAGE_CHANNELS', guild.id)) && (
          <MenuItem
            className="flex items-center justify-between cursor-pointer"
            onClick={() => dispatch(ui.openedModal('CreateCategory'))}>
            <span>Create Category</span>
            <FontAwesomeIcon icon={faPlus} />
          </MenuItem>
        )}  */}



      <hr className="my-2 border-bg-primary" />


      <MenuItem
        className="flex items-center justify-between danger cursor-pointer"
        onClick={() => dispatch(leaveGuild(guild.id))}>
        <span>Leave Guild</span>
        <FontAwesomeIcon icon={faDoorOpen} />
      </MenuItem>
      {devMode && <DevModeMenuSection ids={[
        { title: 'Guild ID', id: guild.id },
      ]} />    
      }
      {devMode && <MenuItem
        className="flex items-center justify-between cursor-pointer"
        onClick={() => navigator.clipboard.writeText(guild.id)}>
        <span>Copy Guild ID</span>
        <FontAwesomeIcon icon={faClipboard} />
      </MenuItem>    
      }
    </ContextMenu>
  );
}

export default GuildMenu;