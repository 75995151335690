import { faUpload, faSmile, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePerms from '../../../hooks/use-perms';
import { uploadFileAsMessage } from '../../../store/messages';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { addEmoji } from './message-box-input';
import { send } from 'process';



interface MessageBoxRightSideProps {
  content: string;
  editingMessageId?: string;
  contentState: [any, any];
  saveEdit: () => any;
}

const MessageBoxRightSide: React.FunctionComponent<MessageBoxRightSideProps> = (props) => {
  const channel = useSelector((s: Store.AppState) => s.ui.activeChannel)!;
  const dispatch = useDispatch();
  const perms = usePerms();
  const messageBoxRef = document.getElementById("messageBox");
  const [content, setContent] = props.contentState;

  const uploadInput = React.createRef<HTMLInputElement>();
  const onChange: any = (e: Event) => {
    const input = e.target as HTMLInputElement;
    dispatch(uploadFileAsMessage(channel.id, { content: props.content }, input.files![0]));
  }

  const [showEmoji, setShowEmoji] = useState(false);



  // const canSendFiles = perms.canInChannel('SEND_FILES', channel.guildId, channel.id);
  const sendmes = () => {
    props.saveEdit();
    setContent('');
    messageBoxRef.innerText = ''
  }




  return (!props.editingMessageId) ? (
    <div style={{ width: "4%" }}>
      <div className={classNames('px-4')} style={{ float: "right" }}>

        <div className="relative z-40" >
          <FontAwesomeIcon
            // color={}
            icon={faPaperPlane}
            onClick={sendmes}
            className={classNames('cursor-pointer z-1')} />
        </div>

      </div>


    </div>

  ) : null;
}
export default MessageBoxRightSide;