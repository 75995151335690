import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';

const UserDropdown: React.FunctionComponent = () => {
  // return null;
    return (<h1 className='flex-grow font-bold pl-2 p-3'>
      <FontAwesomeIcon
        data-tip
        data-for={'secureTooltip'}
        icon={faLock}
        color='var(--success)'
        className='mr-2' />
      <ReactTooltip
        id='secureTooltip'
        backgroundColor='var(--bg-tertiary)'
        effect='solid'>
        Messages
      </ReactTooltip>
      <span>Private Messages</span>
    </h1>
  );
}

export default UserDropdown;