import { Entity } from '@hamcord/types';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DevModeMenuSection from './dev-mode-menu-section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { deleteMessage } from '../../store/messages';
import usePerms from '../../hooks/use-perms';
import { actions as ui } from '../../store/ui';

export interface MessageMenuProps {
  message: Entity.Message;
}

const MessageMenu: React.FunctionComponent<MessageMenuProps> = ({ message }) => {
  const dispatch = useDispatch();
  const { guildId }: any = useParams();
  const devMode = useSelector((s: Store.AppState) => s.config.devMode);

  const selfUser = useSelector((s: Store.AppState) => s.auth.user)!;
  const guild = useSelector((s: Store.AppState) => s.ui.activeGuild)!;
  const perms = usePerms();

  const isAuthor = message.authorId === selfUser.id;
  let canManage = null;

  if (!guild){
    canManage = false;
  } else {
    console.log("Running this check CanMan Menu")
    canManage = perms.canInChannel('MANAGE_MESSAGES', guild.id, message.channelId)
    || guild?.ownerId === selfUser.id
    || isAuthor;
  }

  return (guildId) ? (
    <ContextMenu
      key={message.id}
      id={message.id}
      className="bg-bg-tertiary rounded shadow w-56 p-2">
      <div className="overflow-hidden">
        <span className="bg-bg-primary p-1 rounded max-w-full">{message.content}</span>
        <br></br>

        {isAuthor && (
          <div className="inline">
            <hr className="my-2 border-bg-primary" />

            <MenuItem
              onClick={() => dispatch(ui.startedEditingMessage(message.id))}
              className="flex items-center justify-between  cursor-pointer">
              <span>Edit Message</span>
              <FontAwesomeIcon

                className="mr-2"
                icon={faPencilAlt} />
            </MenuItem>
          </div>
        )}


        {canManage && (
          <div className="inline">
            <hr className="my-2 border-bg-primary" />

            <MenuItem
              onClick={() => dispatch(deleteMessage(message.id))}
              className="flex items-center justify-between  cursor-pointer danger">
              <span>Delete Message</span>
              <FontAwesomeIcon

                className="danger"
                icon={faTimes} />
            </MenuItem>
          </div>
        )}




      </div>
      <hr className="my-2 border-bg-primary" />
      {devMode && <DevModeMenuSection ids={[
        { title: 'Message ID', id: message.id },
      ]} 
      />}
    </ContextMenu>
  ) : null;
}

export default MessageMenu;