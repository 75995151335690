import { faUpload, faSmile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePerms from '../../../hooks/use-perms';
import { uploadFileAsMessage } from '../../../store/messages';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { addEmoji } from './message-box-input';



interface MessageBoxLeftSideProps {
  content: string;
  editingMessageId?: string;
}

const MessageBoxLeftSide: React.FunctionComponent<MessageBoxLeftSideProps> = (props) => {
  const channel = useSelector((s: Store.AppState) => s.ui.activeChannel)!;
  const dispatch = useDispatch();
  const perms = usePerms();

  const uploadInput = React.createRef<HTMLInputElement>();
  const onChange: any = (e: Event) => {
    const input = e.target as HTMLInputElement;
    dispatch(uploadFileAsMessage(channel.id, { content: props.content }, input.files![0]));
  }

  const [showEmoji, setShowEmoji] = useState(false);



  let canSendFiles = null;
  if (!channel.guildId){
    canSendFiles = true
  } else {
    console.log("Running this check CanMan Menu")
    canSendFiles = perms.canInChannel('SEND_FILES', channel.guildId, channel.id);
  }

 


  return (!props.editingMessageId) ? (
    <div style={{width: "4%"}}>
      <div className={classNames('px-4')} style={{ float: "left" }}>

        <div className="relative z-40" >
          <input
            disabled={!canSendFiles}
            ref={uploadInput}
            type="file"
            name="file"
            // accept="image/*"
            onChange={onChange}
            hidden />
          <FontAwesomeIcon
            color={canSendFiles ? '#ffffff' : 'var(--muted)'}
            icon={faUpload}
            onClick={() => uploadInput.current?.click()}
            className={classNames('cursor-pointer z-1')} />
        </div>

      </div>
      <div className={classNames('px-4')} style={{ float: "left" }}>
        <div className="relative">
          <FontAwesomeIcon
            color={canSendFiles ? '#ffffff' : 'var(--muted)'}
            icon={faSmile}
            onClick={(event) => {
              event.stopPropagation();
              setShowEmoji(!showEmoji)
            }}
            className={classNames('cursor-pointer z-1')} />
        </div>
        

      </div>
      {showEmoji && <div style={{position: "absolute",bottom: "11%"}}> 
      <Picker data={data} onEmojiSelect={emoji => addEmoji(emoji)}  onClickOutside={() => {setShowEmoji(!showEmoji);}}/>
    </div>}
    </div>
    
  ) : null;
}
export default MessageBoxLeftSide;